import React from 'react'
import fb_icon from '../Assets/fb_con.png'
import insta_icon from '../Assets/instagram_icon.png'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer-main'>
<div className="footer-head"></div>
<div className="footer-media"><img src={fb_icon} alt="" />
<img src={insta_icon} alt="" /></div>

    </div>
  )
}

export default Footer