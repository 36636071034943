import './App.css';
import Greets from './Components/Greets/Greets';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Greets></Greets>
      <Footer></Footer>
    </div>
  );
}

export default App;
